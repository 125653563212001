import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import { isSameDay, isSunday } from 'date-fns';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import AddToBasketConfirmationDialog from '@/components/AddToBasketConfirmationDialog/AddToBasketConfirmationDialog';
import ProductAutocompleteField from '@/components/ProductAutocompleteField/ProductAutocompleteField';
import HourCounter from '@/components/ui/HourCounter/HourCounter';
import InputCalendar from '@/components/ui/InputCalendar/InputCalendar';
import InputCounter from '@/components/ui/InputCounter/InputCounter';
import Snackbar from '@/components/ui/Snackbar/Snackbar';
import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import { HOURS } from '@/helpers/constants';
import { getHoursResetValue } from '@/helpers/directRequest';
import { useBasketContext } from '@/hooks/useBasketContext';
import { useBasketPreviewContext } from '@/hooks/useBasketPreviewContext';
import { useDirectRequestFormContext } from '@/hooks/useDirectRequestFormContext';
import useFormatMessage from '@/hooks/useFormatMessage';

import {
  ActionButton,
  ButtonsWrapper,
  CounterWrapper,
  DateRangePickerWrapper,
  Heading,
  MainWrapper,
  Wrapper,
} from './DirectRequestForm.style';

const validationSchema = Yup.object({
  rentalObject: Yup.object({
    name: Yup.string()
      .trim()
      .required(),
    productReferenceId: Yup.number().nullable(),
    salesforceId: Yup.string().nullable(),
    productGroupName: Yup.string().nullable(),
    productGroupId: Yup.number().nullable(),
    automatedOffer: Yup.bool(),
    minLeadTime: Yup.number().nullable(),
    rentalPriceBasis: Yup.string().nullable(),
    rentalPrice: Yup.number().nullable(),
    invoiceBasis: Yup.string().nullable(),
  }),
  count: Yup.number().required(),
  dateFrom: Yup.date().required(),
  dateTo: Yup.date().required(),
  rentalHours: Yup.number().nullable(),
});

const DirectRequestForm = React.forwardRef(
  (
    {
      className,
      onSubmit,
      onRedirectWithItemsInBasket,
      closeDialog,
      showInfoText,
      showAddNextItemButton,
      submitButtonLabel,
    },
    ref
  ) => {
    const router = useRouter();
    const {
      requestData,
      updateRequestData,
      resetRentalObjectData,
    } = useDirectRequestFormContext();
    const {
      addRequestToBasket,
      requestsInBasketCount,
      machineAlreadyInBasket,
    } = useBasketContext();
    const { openBasketPreview } = useBasketPreviewContext();
    const formatMessage = useFormatMessage();

    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [duplicatedMachineData, setDuplicatedMachineData] = useState(null);

    const {
      control,
      handleSubmit,
      setValue,
      watch,
      setFocus,
      formState: { isSubmitting },
      clearErrors,
    } = useForm({
      defaultValues: requestData,
      mode: 'onSubmit',
      resolver: yupResolver(validationSchema),
    });

    const dateFromWatched = watch('dateFrom');
    const dateToWatched = watch('dateTo');
    const rentalObjectName = watch('rentalObject.name');
    const rentalObjectInvoiceBasis = watch('rentalObject.invoiceBasis');
    const rentalHoursWatched = watch('rentalHours');

    const showHourCounter =
      HOURS.includes(rentalObjectInvoiceBasis) &&
      isSameDay(dateFromWatched, dateToWatched);

    const insideDialog = !!closeDialog;

    const onSnackbarClose = (e, reason) => {
      if (reason !== 'clickaway') {
        setShowSuccessSnackbar(false);
      }
    };

    const handleOnChange = ({ data, inputName, value, onChange }) => {
      let updatedData = {};

      if (data) {
        updatedData = data;
      } else if (inputName) {
        updatedData = { [inputName]: value };
      }

      updateRequestData(updatedData);
      onChange(value);
    };

    const handleHoursChangeFromRentalObject = value => {
      if (HOURS.includes(value?.invoiceBasis)) {
        setValue(
          'rentalHours',
          getHoursResetValue(dateFromWatched, dateToWatched)
        );
      } else if (rentalHoursWatched !== null) {
        setValue('rentalHours', null);
      }
    };

    const handleDateFromChange = (value, onChange) => {
      handleOnChange({
        data: { dateFrom: value, dateTo: null },
        value,
        onChange,
      });
      setValue('dateTo', null, { shouldDirty: true });
      setFocus('dateTo');
    };

    const handleDateToChange = newDateTo => {
      if (isSameDay(newDateTo, dateToWatched)) return;
      if (dateFromWatched && HOURS.includes(rentalObjectInvoiceBasis)) {
        setValue('rentalHours', getHoursResetValue(dateFromWatched, newDateTo));
      }
    };

    const handleDuplicatedMachine = (callback, withRedirect = false) => (
      values,
      event
    ) => {
      if (machineAlreadyInBasket(values.rentalObject)) {
        setDuplicatedMachineData({
          machineName: values.rentalObject.name,
          values,
          withRedirect,
        });
      } else {
        callback(values, event);
      }
    };

    const handleFormSubmit = async values => {
      addRequestToBasket(values);
      resetRentalObjectData();
      onSubmit();
      if (router.pathname !== '/basket') {
        await router.push('/basket');
      } else {
        setDuplicatedMachineData(null);
      }
    };

    const handleOnSubmitError = async () => {
      if (requestsInBasketCount > 0 && !rentalObjectName.trim()) {
        clearErrors();
        resetRentalObjectData();
        onRedirectWithItemsInBasket();
        if (router.pathname !== '/basket') {
          await router.push('/basket');
        }
      }
    };

    const handleAddMachine = (values, event) => {
      addRequestToBasket(values);
      resetRentalObjectData();
      setValue('rentalObject', {
        name: '',
        productReferenceId: null,
        salesforceId: null,
        productGroupName: null,
        productGroupId: null,
        automatedOffer: false,
        minLeadTime: null,
        rentalPriceBasis: null,
        rentalPrice: null,
        invoiceBasis: null,
      });
      setValue('count', 1);
      setFocus('rentalObject');
      setValue('rentalHours', null);

      setShowSuccessSnackbar(true);
      if (!insideDialog) {
        openBasketPreview({ closeTimeout: 10000, event });
      }
      setDuplicatedMachineData(null);
    };

    const handleAddDuplicatedMachine = () => {
      if (duplicatedMachineData.withRedirect) {
        handleAddMachine(duplicatedMachineData.values);
      } else {
        handleFormSubmit(duplicatedMachineData.values);
      }
    };
    const handleDontAddDuplicatedMachine = async () => {
      resetRentalObjectData();
      if (router.pathname !== '/basket') {
        closeDialog?.();
        await router.push('/basket');
      } else {
        setDuplicatedMachineData(null);
      }
    };

    const FormWrapper = insideDialog ? React.Fragment : MainWrapper;

    return (
      <>
        <Wrapper className={className} ref={ref} data-cy="form: direct request">
          <FormWrapper>
            <Heading variant="h4" component="div">
              {formatMessage('dr_form_header')}
            </Heading>
            <form
              noValidate
              onSubmit={handleSubmit(
                handleDuplicatedMachine(handleFormSubmit),
                handleOnSubmitError
              )}>
              <Controller
                name="rentalObject"
                control={control}
                render={({
                  field: { ref, value, onChange, name, onBlur },
                  fieldState: { error },
                }) => (
                  <ProductAutocompleteField
                    required
                    error={!!error}
                    inputRef={ref}
                    helperText={
                      error ? formatMessage('dr_form_machineError') : ' '
                    }
                    value={value?.name}
                    onChange={value => {
                      handleHoursChangeFromRentalObject(value);
                      handleOnChange({
                        onChange,
                        inputName: name,
                        value,
                      });
                    }}
                    onBlur={onBlur}
                    name={name}
                    insideDialog={insideDialog}
                    only="rentable"
                  />
                )}
              />
              <Controller
                name="count"
                control={control}
                render={({
                  field: { ref, value, onChange, onBlur, name },
                  fieldState: { error },
                }) => {
                  return (
                    <CounterWrapper>
                      <InputCounter
                        inputRef={ref}
                        onChange={value => {
                          handleOnChange({ onChange, inputName: name, value });
                        }}
                        onBlur={onBlur}
                        value={value}
                        min={1}
                        error={error}
                        name={name}
                        label={formatMessage('dr_form_quantity')}
                      />
                    </CounterWrapper>
                  );
                }}
              />
              <DateRangePickerWrapper>
                <Controller
                  name="dateFrom"
                  control={control}
                  render={({
                    field: { ref, value, onChange, onBlur, name },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputCalendar
                        value={value}
                        id="directRequestDateFrom"
                        label={formatMessage('dr_form_start_date')}
                        onChange={e => handleDateFromChange(e, onChange)}
                        onBlur={onBlur}
                        disabledTo={new Date()}
                        isDateDisabled={isSunday}
                        tabIndex={0}
                        inputRef={ref}
                        name={name}
                        error={!!error}
                        helperText={
                          error
                            ? formatMessage('dr_form_mandatoryInputsHelper', {
                                inputName: formatMessage('dr_form_start_date'),
                              })
                            : ' '
                        }
                        required
                      />
                    );
                  }}
                />

                <Controller
                  name="dateTo"
                  control={control}
                  render={({
                    field: { ref, value, onChange, onBlur, name },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputCalendar
                        value={value}
                        id="directRequestDateTo"
                        label={formatMessage('dr_form_end_date')}
                        onChange={value => {
                          handleOnChange({ onChange, inputName: name, value });
                          handleDateToChange(value);
                        }}
                        onBlur={onBlur}
                        disabledTo={dateFromWatched || new Date()}
                        isDateDisabled={isSunday}
                        tabIndex={0}
                        inputRef={ref}
                        name={name}
                        error={!!error}
                        helperText={
                          error
                            ? formatMessage('dr_form_mandatoryInputsHelper', {
                                inputName: formatMessage('dr_form_end_date'),
                              })
                            : ' '
                        }
                        required
                        presented={
                          dateFromWatched
                            ? new Date(
                                new Date(dateFromWatched).setDate(
                                  new Date(dateFromWatched).getDate() + 1
                                )
                              )
                            : new Date()
                        }
                      />
                    );
                  }}
                />
              </DateRangePickerWrapper>
              {showHourCounter && (
                <Controller
                  name="rentalHours"
                  control={control}
                  render={({
                    field: { ref, value, onChange, onBlur, name },
                  }) => {
                    return (
                      <HourCounter
                        ref={ref}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        onHourChange={updatedValue => {
                          handleOnChange({
                            onChange,
                            inputName: name,
                            value: updatedValue,
                          });
                        }}
                      />
                    );
                  }}
                />
              )}
              <ButtonsWrapper>
                {showAddNextItemButton && (
                  <ActionButton
                    size="large"
                    color="primaryLight"
                    fullWidth
                    data-cy="button: addNextItem"
                    onClick={handleSubmit(
                      handleDuplicatedMachine(handleAddMachine, true)
                    )}
                    tabIndex={-1}
                    disabled={isSubmitting}>
                    {formatMessage('dr_form_add_machine')}
                  </ActionButton>
                )}
                <ActionButton
                  size="large"
                  data-cy="button: addToBasket"
                  type="submit"
                  fullWidth
                  tabIndex={0}
                  disabled={isSubmitting}
                  startIcon={
                    <SvgIcon name="shoppingCart" sx={{ mb: '3px' }} />
                  }>
                  {submitButtonLabel ?? formatMessage('dr_form_cta')}
                </ActionButton>
              </ButtonsWrapper>
              {showInfoText && (
                <Typography
                  variant="caption"
                  sx={{ color: theme => theme.palette.grayscale[600] }}>
                  {formatMessage('dr_form_mandatory')}
                </Typography>
              )}
            </form>
          </FormWrapper>
        </Wrapper>
        <Snackbar
          severity="success"
          onClose={onSnackbarClose}
          open={showSuccessSnackbar}
          message={formatMessage('dr_form_snackbar')}
          SnackbarProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
        />
        <AddToBasketConfirmationDialog
          open={!!duplicatedMachineData}
          onAddAgain={handleAddDuplicatedMachine}
          onDontAddAgain={handleDontAddDuplicatedMachine}
          machineName={duplicatedMachineData?.machineName}
        />
      </>
    );
  }
);

DirectRequestForm.displayName = 'DirectRequestForm';

export const DirectRequestFormPropTypes = {
  className: PropTypes.string,
  /** Function triggered after DirectRequestForm's onSubmit handler. */
  onSubmit: PropTypes.func,
  onRedirectWithItemsInBasket: PropTypes.func,
  showInfoText: PropTypes.bool,
  showAddNextItemButton: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  closeDialog: PropTypes.func,
};

DirectRequestForm.propTypes = DirectRequestFormPropTypes;

DirectRequestForm.defaultProps = {
  className: '',
  onSubmit: () => {},
  onRedirectWithItemsInBasket: () => {},
  showInfoText: true,
  showAddNextItemButton: true,
  submitButtonLabel: null,
  closeDialog: null,
};
export default DirectRequestForm;
